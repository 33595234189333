<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  },
  created() {
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
      sessionStorage.removeItem("store");
    }
    window.addEventListener("unload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  methods: {

  },
};
</script>


<style lang="less">
#app {
  overflow: hidden;
}
* {
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.van-tab__pane-wrapper {
  overflow: hidden !important;
}
.van-tabs__content--animated {
  overflow: hidden;
}
</style>
