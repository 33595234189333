import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import util from '@/utils/util.js'
import wx from 'weixin-js-sdk'
// import 'viewerjs/dist/viewer.css'
import 'vant/lib/index.css';

Vue.config.productionTip = false
Vue.use(Vant);
Vue.use(wx);
Vue.prototype.$EventBus = new Vue()
Vue.prototype.$util = util;
Vue.prototype.$wx = wx
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')