import axios from 'axios';
import CryptoJS from 'crypto-js';
import VueCookies from 'vue-cookies';
import jssdk from "weixin-js-sdk"
export default {
    api(url, params, callback) {
        var nowDate = new Date();
        var dateTime = nowDate.toUTCString();
        var SecretId = 'AKIDioGe39j2r6579nXpoy806efUw9l9Wjv5p3ni';
        var SecretKey = 'C3fPT3K3LFH30IN0JYsaK5Lc3psjulcr2baQ5c40';
        var source = 'distribution';
        var auth = "hmac id=\"" + SecretId + "\", algorithm=\"hmac-sha1\", headers=\"x-date source\", signature=\"";
        var signStr = "x-date: " + dateTime + "\n" + "source: " + source;
        var sign = CryptoJS.HmacSHA1(signStr, SecretKey);
        sign = CryptoJS.enc.Base64.stringify(sign);
        sign = auth + sign + "\"";
        var instance = axios.create({
            baseURL: '/api',
            // baseURL:'https://distribution.api.91jf.com',
            timeout: 5000,
            headers: {
                "Source": source,
                "X-Date": dateTime,
                "version": "0.1",
                "Authorization": sign,
            },
            withCredentials: false
        });

        if (!params) {
            params = {};
        }
        instance.post(url, {
            ...params
        }).then((response) => {
            let status = response.status
            let data = response.data
            if (status !== 200) {
                console.log('请求失败，状态码：' + status)
            }
            if (callback) {
                callback(data)
            }
        }).catch((error) => {
            console.log(error)
        })
    },
}