import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: "/index.php"
  },
  {
    path: '/index.php',
    name: 'Home',
    component: () => import('../views/Home'),
    meta: {
      title: "商品",
      roles: ['admin']
    }
  },
  {
    path: '/illegal',
    name: 'IllegalRequest',
    component: () => import('../views/IllegalRequest'),
    meta: {
      title: ""
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router